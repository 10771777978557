import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false


import 'bootstrap/dist/css/bootstrap.css' //引用bootstrap的样式
import 'bootstrap/dist/js/bootstrap.min.js' //引用bootstrap的js

import 'bootstrap-icons/font/bootstrap-icons.css';//引用bootstrap-icons的样式

import 'wowjs/css/libs/animate.css'; //引入animate.css 用于wow

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
