<template>
  <div>

    <!-- 轮播图 -->
    <div class="container-fluid p-0 mb-5">
      <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100" src="../assets/img/CAR_WASH_1.jpg" alt="Image">
            <div class="carousel-caption">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-8">
                    <h1 class="display-1 mb-4  text-white wow animate__slideInDown">
                      享受生活，分享快乐
                    </h1>
                    <a href="" class="btn btn-primary py-3 px-5 wow animate__bounceInRight">了解更多</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img class="w-100" src="../assets/img/CAR_WASH_2.jpg" alt="Image">
            <div class="carousel-caption">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-7">
                    <!-- <p
                                        class="d-inline-block border border-white rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown">
                                          Welcome to Finanza</p> -->
                    <h1 class="display-1 mb-4 animate__animated wow text-white animate__bounceInDown">享受生活，分享快乐</h1>
                    <a href="" class="btn btn-primary py-3 px-5 animate__animated animate__bounceInDown">了解更多</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

    <!-- About Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-4 align-items-end mb-4">
          <div class="col-lg-6 wow fadeInLeft" data-wow-delay="0.3s">
            <img class="img-fluid rounded" src="../assets/img/CAR_WASH_1.jpg">
          </div>
          <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
            <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">关于我们</p>
            <h1 class="display-6 mb-2">成都小车飞象环保科技有限公司</h1>
            <p class="mb-4">成都小车飞象环保科技有限公司成立于2021年，创始人团队深耕汽车清洁、保养、养护行业15年，致力于利用大数据、物联网、人工智能等先进技术赋能传统汽车行业</p>
            <div class="border rounded p-4">
              <nav>
                <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  <button class="nav-link fw-semi-bold active" id="nav-story-tab" data-bs-toggle="tab"
                    data-bs-target="#nav-story" type="button" role="tab" aria-controls="nav-story"
                    aria-selected="true">创新</button>
                  <button class="nav-link fw-semi-bold" id="nav-mission-tab" data-bs-toggle="tab"
                    data-bs-target="#nav-mission" type="button" role="tab" aria-controls="nav-mission"
                    aria-selected="false">核心赋能</button>
                  <button class="nav-link fw-semi-bold" id="nav-vision-tab" data-bs-toggle="tab"
                    data-bs-target="#nav-vision" type="button" role="tab" aria-controls="nav-vision"
                    aria-selected="false">团队理念</button>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-story" role="tabpanel" aria-labelledby="nav-story-tab">
                  <p>创新的采用物联网、大数据赋能传统洗车行业</p>
                  <p class="mb-0">实现无人自助洗车站点的全面建设</p>
                </div>
                <div class="tab-pane fade" id="nav-mission" role="tabpanel" aria-labelledby="nav-mission-tab">
                  <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos
                    labore.</p>
                  <p class="mb-0">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat
                    ipsum et lorem et sit</p>
                </div>
                <div class="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                  <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos
                    labore.</p>
                  <p class="mb-0">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat
                    ipsum et lorem et sit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border rounded p-4 wow fadeIn">
          <div class="row g-4">
            <div class="col-lg-4">
              <div class="h-100 d-flex align-items-center">
                <div class="border rounded-circle bg-primary d-flex justify-content-center align-items-center "
                  style="height:48px;width:48px">
                  <i class="bi bi-camera fs-4 text-white"></i>
                </div>
                <div class="px-2 w-75">
                  <h4>安全环保</h4>
                  <span>始终贯彻环保理念，用最少的资源完成车辆清洁</span>
                </div>
                <div class="border-end d-none d-lg-block"></div>
                <div class="border-bottom mt-4 d-block d-lg-none"></div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="d-flex align-items-center h-100">
                <div class="border rounded-circle bg-primary d-flex justify-content-center align-items-center "
                  style="height:48px;width:48px">
                  <i class="bi bi-people fs-4 text-white"></i>
                </div>
                <div class="px-2 w-75">
                  <h4>便宜实惠</h4>
                  <span>按量付费，使用多少，付多少费用</span>
                </div>
                <div class="border-end d-none d-lg-block"></div>
              </div>
              <div class="border-bottom mt-4 d-block d-lg-none"></div>
            </div>
            <div class="col-lg-4">
              <div class="h-100 d-flex align-items-center">
                <div class="border rounded-circle bg-primary d-flex justify-content-center align-items-center "
                  style="height:48px;width:48px">
                  <i class="bi bi-telephone fs-4 text-white"></i>
                </div>
                <div class="px-2 w-75">
                  <h4>7 * 24 小时服务</h4>
                  <span>随时提供服务，无需人员值守，省去大笔人工费用</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End-->

    <!-- Facts Start -->
    <div ref="target" class="container-fluid facts my-5 py-5 wow bounceIn">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-sm-6 col-lg-3 text-center" data-wow-delay="0.1s">
            <i class="bi bi-people-fill fs-1 text-white mb-3"></i>
            <h1 class="display-4 text-white">
              <CountTo ref="ct1" :autoplay="false" :startVal="0" :endVal="10" :duration="2000"></CountTo>
            </h1>
            <span class="fs-5 text-white">站点个数</span>
            <hr class="bg-white w-25 mx-auto mb-0">
          </div>
          <div class="col-sm-6 col-lg-3 text-center" data-wow-delay="0.3s">
            <i class="bi bi-cloud-fill fs-1  text-white mb-3"></i>
            <h1 class="display-4 text-white">
              <CountTo ref="ct2" :autoplay="false" :startVal="0" :endVal="100000" :duration='2000'></CountTo>
            </h1>
            <span class="fs-5 text-white">注册人数</span>
            <hr class="bg-white w-25 mx-auto mb-0">
          </div>
          <div class="col-sm-6 col-lg-3 text-center" data-wow-delay="0.5s">
            <i class="bi bi-gift-fill fs-1  text-white mb-3"></i>
            <h1 class="display-4 text-white">
              <CountTo ref="ct3" :autoplay="false" :startVal="0" :endVal="300000" :duration='2000'></CountTo>
            </h1>
            <span class="fs-5 text-white">服务次数</span>
            <hr class="bg-white w-25 mx-auto mb-0">
          </div>
          <div class="col-sm-6 col-lg-3 text-center" data-wow-delay="0.7s">
            <i class="bi bi-check-circle-fill fs-1  text-white mb-3"></i>
            <h1 class="display-4 text-white ">
              <CountTo ref="ct4" :autoplay="false" :startVal="0" :endVal="12" :duration='2000'></CountTo>
            </h1>
            <span class="fs-5 text-white">加盟商个数</span>
            <hr class="bg-white w-25 mx-auto mb-0">
          </div>
        </div>
      </div>
    </div>
    <!-- Facts End -->
  </div>
</template>

<script >
import { WOW } from 'wowjs'
import CountTo from 'vue-count-to'


// import { CountTo } from 'vue3-count-to';
// import { onMounted, ref } from 'vue'
// import { useIntersectionObserver } from "@vueuse/core";


export default {
  name: 'Home',
  components: {
    CountTo
  },
  mounted () {
    new WOW().init()  //初始化wowjs

        for (let refName in this.$refs) {
          console.log('refName', refName);
        }

    const target = this.$refs.target;
    console.log(target, 'target');
    // 创建 IntersectionObserver 对象
    const observer = new IntersectionObserver(entries => {
      // 在这里处理交叉状态变化
      console.log(entries, entries.isIntersecting);
      if (entries[0].isIntersecting) {
        console.log('元素进入视野!');
        console.log('this.$refs.ct1', this.$refs.ct1);
        this.$refs.ct1.start()
        this.$refs.ct2.start()
        this.$refs.ct3.start()
        this.$refs.ct4.start()
      } else {
        console.log('元素离开视野!');
        this.$refs.ct1.reset()
        this.$refs.ct2.reset()
        this.$refs.ct3.reset()
        this.$refs.ct4.reset()
      }
    });
    // 将目标元素传递给 IntersectionObserver 对象
    observer.observe(target);


    /*
        for (let refName in this.$refs) {
          console.log('refName', refName);
    
        }
    
        const facts = document.getElementsByClassName('facts')[0]
    
        console.log('facts',facts)
        const observer =  new IntersectionObserver( (facts) => {
          if (facts.isIntersecting) {
            console.log('元素进入视野!');
          } else {
            console.log('元素离开视野!');
          }
    
        })
    
        observer.observe(facts)
    */

    /*
    const countToComponents = []

    const addCountToComponents = function (components) {
      components.forEach(component => {
        if (component.$refs.ct) {
          console.log('component.$refs.ct', component.$refs.ct);
          countToComponents.push(component.$refs.ct)
        }
        if (component.$children && component.$children.length > 0) {
          addCountToComponents(component.$children)
        }
      })
    }

    addCountToComponents(this.$parent.$children)
    
    console.log('countToComponents', countToComponents);
*/


    // const observer = new IntersectionObserver(entries => {
    //   entries.forEach(entry => {
    //     if (entry.isIntersecting) {
    //       console.log(`${entry.target.textContent} has entered the viewport`);
    //       // Do something when component is visible
    //     } else {
    //       console.log(`${entry.target.textContent} has left the viewport`);
    //       // Do something when component is invisible
    //     }
    //   });
    // });

    // this.$refs.countto.forEach(item => {
    //   observer.observe(item)
    // })
  }
}
</script>

<style scoped>
.facts {
  background: linear-gradient(rgba(53, 94, 252, 0.95), rgba(53, 94, 252, 0.95)),
    url(../assets/img/bg.png);
}
</style>
