import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  ,

  {
    path: '/about',
    name: 'About',
    //lazy-loaded 
    component: () => import('@/views/About.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/Product.vue')
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('@/views/Invite.vue')
  },
  {
    path: '/cases',
    name: 'Cases',
    component: () => import('@/views/Cases.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
