<template>

  <nav class="navbar navbar-expand-lg navbar-white bg-light px-4">
    <a class="d-inline-block ms-3" href="#">
      <img src="../assets/img/logo.png" alt="Logo" class="d-inline-block align-text-top" width="80">
    </a>

    <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto p-4 p-lg-0">

        <router-link to="/" class="nav-item nav-link active">首页</router-link>
        <router-link to="/about" class="nav-item nav-link active">关于我们</router-link>
        <router-link to="/product" class="nav-item nav-link active">产品介绍</router-link>
        <router-link to="/invite" class="nav-item nav-link active">诚邀加盟</router-link>
        <router-link to="/cases" class="nav-item nav-link active">案例展示</router-link>
        <router-link to="/cases" class="nav-item nav-link active">联系我们</router-link>
      </div>
    </div>

  </nav>

</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {}
  },
  mounted () {
    const header = document.querySelector('.navbar');
    const headerHeight = header.clientHeight;

    window.addEventListener('scroll', () => {
      if (window.scrollY > headerHeight) {
        header.classList.add('shadow', 'fixed-top');
      } else {
        header.classList.remove('shadow', 'fixed-top');
      }
    });

  }
}
</script>

<style>
</style>